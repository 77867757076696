import React, { useEffect, useContext } from "react"
import BannerContext from "../lib/context"
import Helmet from "react-helmet"

import Layout from '../components/layout'
import Header from '../components/Header'
import Section, { TextSection, ContainerSection, BackgroundSection, HalfBackgroundSection } from '../components/Section'


import { getHomeHeader, getStaticPageData } from "../lib/API"
import { useData, useLocalData } from "../lib/hooks"
import BannerSection from "../components/BannerSection"


// import TotsImage from '../assets/images/home/parents-and-tots.jpg'
import TotsImage from '../assets/images/groups/1.jpg'


// import PastoralImage from '../assets/images/home/pastoral-care3.jpg'
import PastoralImage from '../assets/images/core/PastoralCare2.jpg'
import Marathon from '../assets/images/church/marathon1.jpg'

import CafeImage from '../assets/images/home/cafe.jpg'
import ShelterImage from '../assets/images/home/shelter.jpg'
// import FoodBankImage from '../assets/images/home/food-bank.jpg'
import FoodBankImage from '../assets/images/general/greenwich-food-bank-logo.webp'

import PersonPersonImage from '../assets/images/home/person-to-person2.jpg'
import CraftsImage from '../assets/images/general/25.jpg'





export default () => {

    const siteTitle = "Blackheath and Charlton Baptist Church - Serving"
    const pageName = 'serving-banners'
    const pageData = useContext( BannerContext )[pageName]

    // const pageData = useLocalData( getStaticPageData, pageName, pageName )
    const { header, banners } = pageData || { header: null, banners: null }
    const headerImages = header ? header.images : []
    const headerTitle = header ? header.title : 'Serving Our Community'
    
    // const headerImages = useData( getHomeHeader )
    // const headerTitle = "Serving Our Community"
    // const headerIcon = <span className="icon major"><i className="fas fa-seedling"></i></span>

    
    return (
        <div id="serving-page">
            <Helmet title={siteTitle} />
            <Header images={headerImages} headerTitle={headerTitle} tint={0.3}/>

            {/* <BackgroundSection 
                id="parents-and-tots" 
                title="Parents & TOTS"
                description="TOT, our parents and toddlers’ group, is open to members of our congregation and the community every Tuesday from 9am-11am. All are welcome."
                style="style1"
                background={[ TotsImage ]}
                alt
                blur={0.1}
                tint={0.25}
                
            /> */}

            <TextSection 
                id="pastoral-care" 
                title="Pastoral Care"
                style="style1 large"
                background={[ PastoralImage ]}
                description="The uncertainty caused by the pandemic has deeply impacted many lives in our community. 
                The pastoral care team at BCBC is available to walk alongside anyone facing hardships or challenges. 
                We believe that our love for Jesus Christ should be expressed in the way we love and serve members of our congregation and wider community. 
                This belief is central to how we care for others.   
                Please contact us if you would like a member of our pastoral team to speak with you."
                // alt
                tint={0.45}
                alt
                
            />

            <TextSection
                id="person-to-person" 
                style="style2 large padded"
                title="Person to Person"
                background={[PersonPersonImage]}
                description={`Our vision is to work alongside the retired and elderly in our community, whether in Shelters, Care Homes, or their own homes.

                Loneliness is a common issue, and we aim to share Bible passages, pray, be a Christ-like presence, and meet people at their point of need -whether physical, emotional, psychological, or practical.

                The well-being of our neighbours in the community will be our focus and underpinned by prayer`
                }
                tint={0.3}
                blur={.2}
                
            />

            <HalfBackgroundSection
                id="arnold-house" 
                style="style2"
                title="Arnold House"
                background={[ CafeImage ]}
                description={`Our church family actively engages in fellowship with the elderly residents at Arnold's House, building meaningful relationships and offering support through regular visits and shared moments of community
                `
                }
                tint={.3}     
            />

            <TextSection
                id="thursday-craft" 
                style="style2 large padded"
                title="Thursday Knitting & Craft Group"
                background={[ CraftsImage ]}
                description={`Our group meets every Thursday from 10 am – 12 noon at Corner Stop. Everyone is welcome to join.   
                    You can bring your own needlework, knitting, crochet or other craft.
                    The jumpers, hats and blankets are donated to Christian Hope International and sent to those in need in Eastern Europe.   
                    The muffs, ‘Twiddle Muffs’, are given to dementia patients to keep their hands warm and engaged.`
                }
                opacity={0.5}
                tint={0.5}                
            />
            
           


            <HalfBackgroundSection
                id="london-marathon" 
                style="style2"
                title="London Marathon"
                background={[ Marathon ]}
                description={`Each year, as the London Marathon passes by our church, we welcome spectators with freshly baked cakes and pastries, inviting them to join us for a warm and friendly breakfast. 
                    It's a wonderful opportunity to connect with the community and share in the excitement of the event.`
                }
                tint={.35}
                blur={.1}
                alt               
            />



            {/* <BackgroundSection
                id="homeless-shelter" 
                style="style2"
                title="Homeless Shelter"
                background={[ ShelterImage ]}
                description={`Over the past few years we have been one of the centres for Greenwich Night Shelter. 
                Due to the current pandemic, this has been on hold. 
                However, it is our hope that we will be able to offer this and other key services that are essential to the wellbeing of members of our community in the very near future.`}
                tint={0.2}
            /> */}

            <Section
                id="food-bank" 
                title="Food Bank"
                image={[ FoodBankImage ]}
                description="Greenwich Foodbank operates in our building every Thursday from 11.30 am-2.30 pm. 
                Please contact a member of the team if you are in need or feel free to stop by for a chat."
                tint={0.4}
                blur={0.05}
                alt
                // roundedImage
                buttons={[{external: true, url: 'https://greenwich.foodbank.org.uk/locations/', text: 'Food bank locations'}]}

            />

            <BannerSection banners={banners} />


        </div>
    )
}
